import * as React from "react";
import { StyledEngineProvider } from '@material-ui/core/styles';
import Layout from '../components/Layout';


export default function Pictures() {
  return (
    <StyledEngineProvider injectFirst>
      <Layout subtitle="Page not found">
      </Layout>
    </StyledEngineProvider>
  );
}